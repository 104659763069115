import isEqual from 'lodash/isEqual'
import { Route } from 'vue-router'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface IRoute extends Pick<Route, 'fullPath' | 'name' | 'query' | 'params' | 'hash' | 'meta' | 'path'> {}

export interface IRouteModule {
  name: string | null
  params: Record<string, string | undefined>
  query: Record<string, string | string[] | null | undefined>

  currentRoute: IRoute | null
  history: IRoute[]
  historyCursor: number
}

const name = 'route'

const MAX_HISTORY = 50

@Module({
  name,
  namespaced: true
})
export class RouteModule extends VuexModule implements IRouteModule {
  static namespace = name

  name: string | null = null
  params: Record<string, string | undefined> = {
    landscapeId: undefined,
    organizationId: undefined,
    shortId: undefined,
    versionId: undefined
  }

  query: Record<string, string | string[] | null | undefined> = {
    accuracy_help_dialog: undefined,
    accuracy_menu: undefined,
    billign_cycle: undefined,
    billing_currency: undefined,
    bitbucket_server_token_update: undefined,
    code: undefined,
    comment: undefined,
    comments_hidden: undefined,
    comments_list_menu: undefined,
    connection: undefined,
    connection_flip_dialog: undefined,
    connection_reassign_dialog: undefined,
    create_api_key: undefined,
    diagram: undefined,
    diagram_delete_dialog: undefined,
    diagram_search: undefined,
    domain: undefined,
    drawer: undefined,
    error: undefined,
    exclude_tag: undefined,
    expanded: undefined,
    flow: undefined,
    flow_delete_dialog: undefined,
    flow_parent: undefined,
    flow_path: undefined,
    flow_step: undefined,
    focus: undefined,
    group_diagrams_dialog: undefined,
    include_tag: undefined,
    installation_id: undefined,
    landscape_delete_dialog: undefined,
    model: undefined,
    model_objects_menu: undefined,
    object: undefined,
    object_delete_dialog: undefined,
    object_focus: undefined,
    object_parent_update_dialog: undefined,
    object_tab: undefined,
    object_type_update_dialog: undefined,
    organization_upgrade_dialog: undefined,
    overlay_group: undefined,
    overlay_hide: undefined,
    overlay_pin: undefined,
    overlay_tab: undefined,
    plan: undefined,
    recommendations_menu: undefined,
    registered: undefined,
    registered_email: undefined,
    remove_api_key_dialog: undefined,
    revert_version_dialog: undefined,
    route: undefined,
    search: undefined,
    seats: undefined,
    share_dialog: undefined,
    sort: undefined,
    sort_desc: undefined,
    state: undefined,
    tab: undefined,
    tag_delete_dialog: undefined,
    tag_filter_menu: undefined,
    tag_group_delete_dialog: undefined,
    team: undefined,
    team_delete_dialog: undefined,
    toru_step: undefined,
    trial: undefined,
    version_menu: undefined,
    x1: undefined,
    x2: undefined,
    y: undefined,
    y1: undefined,
    y2: undefined
  }

  currentRoute: IRoute | null = null
  history: IRoute[] = []
  historyCursor = 0

  @Mutation
  pruneHistory (predicate: (value: IRoute, index: number, array: IRoute[]) => boolean) {
    this.history = this.history.filter(predicate)
  }

  @Mutation
  setName (name?: string | null) {
    if (typeof name === 'string') {
      if (name !== this.name) {
        this.name = name
      }
    } else {
      this.name = null
    }
  }

  @Mutation
  setQuery (query: Record<string, string | string[]>) {
    Object
      .entries(query)
      .forEach(([key, value]) => {
        // eslint-disable-next-line no-prototype-builtins
        if (this.query.hasOwnProperty(key)) {
          if (!isEqual(this.query[key], value)) {
            this.query[key] = value
          }
        } else {
          this.query = {
            ...this.query,
            [key]: value
          }
        }
      })

    Object
      .keys(this.query)
      .filter(o => query[o] === undefined)
      .forEach(o => {
        // eslint-disable-next-line no-prototype-builtins
        if (this.query.hasOwnProperty(o)) {
          this.query[o] = undefined
        } else {
          this.query = {
            ...this.query,
            [o]: undefined
          }
        }
      })
  }

  @Mutation
  setParams (params: Record<string, string>) {
    Object
      .entries(params)
      .forEach(([key, value]) => {
        // eslint-disable-next-line no-prototype-builtins
        if (this.params.hasOwnProperty(key)) {
          if (!isEqual(this.params[key], value)) {
            this.params[key] = value
          }
        } else {
          this.params = {
            ...this.params,
            [key]: value
          }
        }
      })

    Object
      .keys(this.params)
      .filter(o => params[o] === undefined)
      .forEach(o => {
        // eslint-disable-next-line no-prototype-builtins
        if (this.params.hasOwnProperty(o)) {
          this.params[o] = undefined
        } else {
          this.params = {
            ...this.params,
            [o]: undefined
          }
        }
      })
  }

  @Mutation
  addRoute (route: IRoute) {
    if (this.historyCursor > 0) {
      this.history.splice(0, this.historyCursor)
    }
    this.historyCursor = 0
    this.history = [
      route,
      ...this.history
    ].slice(0, MAX_HISTORY)

    this.currentRoute = route
  }

  @Mutation
  setHistoryCursor (offset: number) {
    this.historyCursor = Math.max(0, Math.min(this.history.length, this.historyCursor + offset))
  }

  @Mutation
  replaceRoute (route: IRoute) {
    this.currentRoute = route

    if (this.history[this.historyCursor]) {
      this.history[this.historyCursor] = route
    }
  }

  @Mutation
  resetHistory () {
    this.history = []
    this.historyCursor = 0
  }
}
