import isEqual from 'lodash/isEqual'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Dictionary, Route } from 'vue-router/types/router'

export type QueryType = Record<string, string | null | undefined | { $union?: any[], $remove?: any[] } | (string | null)[]>

export interface IRouteMixin {
  $routeName: string | null
  $normalizeQuery (query?: Dictionary<string | (string | null)[]>): Record<string, string | string[]>
  $params: Record<string, string>
  $pushQuery (set: QueryType, query?: Record<string, string | string[]>): Promise<Route | undefined>
  $query: Record<string, string | string[] | null>
  $queryArray (key: string): string[]
  $queryValue (key: string): string | null
  $removeQueryArray (...args: string[]): { $remove: string[] }
  $replaceQuery (set: QueryType, query?: Record<string, string | string[]>): Promise<Route | undefined>
  $setQuery (set: QueryType, query?: Record<string, string | string[]>): Record<string, string | string[]>
  $unionQueryArray (...args: string[]): { $union: string[] }
}

@Component
export default class extends Vue implements IRouteMixin {
  get $routeName (): string | null {
    return this.$store.state.route.name
  }

  get $params (): Record<string, string> {
    return this.$store.state.route.params
  }

  get $query (): Record<string, string | string[] | null> {
    return this.$store.state.route.query
  }

  $queryValue (key: string) {
    const val = this.$query[key]
    return val === undefined || val instanceof Array ? null : val
  }

  $queryArray (key: string) {
    const val = this.$query[key]
    return val instanceof Array ? val.filter(o => o) as string[] : val ? [val] : []
  }

  $normalizeQuery (query = this.$route.query) {
    const currentQuery: Record<string, string | string[]> = {}
    Object.keys(query).forEach(o => {
      const val = query[o]
      if (val instanceof Array) {
        const arr = val.filter(o => o) as string[]
        if (arr.length === 0) {
          delete currentQuery[o]
        } else if (arr.length === 1) {
          currentQuery[o] = arr[0]
        } else {
          currentQuery[o] = arr
        }
      } else {
        currentQuery[o] = val
      }
    })
    return currentQuery
  }

  $setQuery (set: QueryType, query = this.$route.query) {
    const currentQuery = this.$normalizeQuery(query)

    Object.entries(set).forEach(([key, val]) => {
      if (val && typeof val === 'object' && ('$union' in val || '$remove' in val)) {
        if ('$union' in val && val.$union) {
          const union = val.$union
          if (currentQuery[key] instanceof Array) {
            currentQuery[key] = [...new Set([...currentQuery[key], ...union])]
          } else if (currentQuery[key]) {
            currentQuery[key] = [...new Set([currentQuery[key], ...union])]
          } else {
            currentQuery[key] = [...union]
          }
        }
        if ('$remove' in val && val.$remove) {
          const remove = val.$remove
          const curVal = currentQuery[key]
          if (curVal instanceof Array) {
            currentQuery[key] = curVal.filter(o => !remove.includes(o))
          } else if (remove.includes(curVal)) {
            delete currentQuery[key]
          }
        }
      } else if (typeof val === 'string') {
        currentQuery[key] = val
      } else if (typeof val === 'number') {
        currentQuery[key] = `${val}`
      } else if (val instanceof Array) {
        currentQuery[key] = val.filter((o): o is string => !!o)
      } else if (val === undefined || val === null) {
        delete currentQuery[key]
      }

      if (currentQuery[key] instanceof Array) {
        if (currentQuery[key].length === 1) {
          currentQuery[key] = currentQuery[key][0]
        } else if (currentQuery[key].length === 0) {
          delete currentQuery[key]
        }
      }
    })

    return currentQuery
  }

  $unionQueryArray (...args: string[]) {
    return { $union: args }
  }

  $removeQueryArray (...args: string[]) {
    return { $remove: args }
  }

  async $replaceQuery (set: QueryType, query = this.$route.query) {
    if (Object.keys(set).length) {
      const updatedQuery = this.$setQuery(set, query)
      if (!isEqual(this.$normalizeQuery(), updatedQuery)) {
        return this.$router.replace({
          query: updatedQuery
        })
      }
    }
  }

  async $pushQuery (set: QueryType, query = this.$route.query) {
    if (Object.keys(set).length) {
      const updatedQuery = this.$setQuery(set, query)
      if (!isEqual(this.$normalizeQuery(), updatedQuery)) {
        return this.$router.push({
          query: updatedQuery
        })
      }
    }
  }
}
