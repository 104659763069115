import { DiagramContent, Domain, Flow, ModelConnection, ModelObject, Tag, TagGroup, Team } from '@icepanel/platform-api-client'
import { Action, Module, VuexModule } from 'vuex-module-decorators'

export interface IRenderModule {}

const name = 'render'

@Module({
  name,
  namespaced: true
})
export class RenderModule extends VuexModule {
  static namespace = name

  @Action({ rawError: true })
  async loadDomainsFromSession () {
    const renderDomains = JSON.parse(sessionStorage.getItem('renderDomains') || '[]') as Domain[]
    this.context.commit('domain/setDomains', renderDomains, { root: true })
  }

  @Action({ rawError: true })
  async loadDiagramContentsFromSession () {
    const renderDiagramContents = JSON.parse(sessionStorage.getItem('renderDiagramContents') || '[]') as DiagramContent[]
    this.context.commit('diagram/setDiagramContents', renderDiagramContents, { root: true })
  }

  @Action({ rawError: true })
  async loadModelFromSession () {
    const renderModelObjects = JSON.parse(sessionStorage.getItem('renderModelObjects') || '[]') as ModelObject[]
    const renderModelConnections = JSON.parse(sessionStorage.getItem('renderModelConnections') || '[]') as ModelConnection[]

    this.context.commit('model/setObjects', renderModelObjects, { root: true })
    this.context.commit('model/setConnections', renderModelConnections, { root: true })
  }

  @Action({ rawError: true })
  async loadTagsFromSession () {
    const renderTags = JSON.parse(sessionStorage.getItem('renderTags') || '[]') as Tag[]
    const renderTagGroups = JSON.parse(sessionStorage.getItem('renderTagGroups') || '[]') as TagGroup[]

    this.context.commit('tag/setTags', renderTags, { root: true })
    this.context.commit('tag/setTagGroups', renderTagGroups, { root: true })
  }

  @Action({ rawError: true })
  async loadFlowsFromSession () {
    const renderFlows = JSON.parse(sessionStorage.getItem('renderFlows') || '[]') as Flow[]
    this.context.commit('flow/setFlows', renderFlows, { root: true })
  }

  @Action({ rawError: true })
  async loadTeamsFromSession () {
    const renderTeams = JSON.parse(sessionStorage.getItem('renderTeams') || '[]') as Team[]
    this.context.commit('team/setTeams', { teams: renderTeams }, { root: true })
  }
}
