import pick from 'lodash/pick'
import { VueConstructor } from 'vue'
import VueRouter, { RawLocation } from 'vue-router'
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import RouteMixin from './mixin'
import { IRoute, RouteModule } from './store'

const EXCLUDE_QUERY_FROM_HISTORY = [
  /_dialog$/,
  /_menu$/
]

export const install = ({ Vue, store, router }: { Vue: VueConstructor, store: Store<any>, router: VueRouter }) => {
  store.registerModule(RouteModule.namespace, RouteModule)

  Vue.mixin(RouteMixin)

  router.onReady(() => {
    const module = getModule(RouteModule, store)
    const prunedRoute: IRoute = structuredClone(pick(router.currentRoute, ['fullPath', 'name', 'query', 'params', 'hash', 'meta', 'path']))

    Object
      .keys(prunedRoute.query)
      .filter(k => EXCLUDE_QUERY_FROM_HISTORY.some(o => o instanceof RegExp ? o.test(k) : o === k))
      .forEach(k => {
        delete prunedRoute.query[k]
      })

    module.addRoute(prunedRoute)
  })

  const routerPush = router.push.bind(router)
  router.push = async (location: RawLocation) => {
    const module = getModule(RouteModule, store)

    const url = new URL(window.location.href)
    url.searchParams.forEach((_, k) => {
      if (EXCLUDE_QUERY_FROM_HISTORY.some(o => o instanceof RegExp ? o.test(k) : o === k)) {
        url.searchParams.delete(k)
      }
    })
    window.history.replaceState({}, '', url.href)

    const route = await routerPush(location)
    const prunedRoute: IRoute = structuredClone(pick(route, ['fullPath', 'name', 'query', 'params', 'hash', 'meta', 'path']))

    Object
      .keys(prunedRoute.query)
      .filter(k => EXCLUDE_QUERY_FROM_HISTORY.some(o => o instanceof RegExp ? o.test(k) : o === k))
      .forEach(k => {
        delete prunedRoute.query[k]
      })

    module.addRoute(prunedRoute)
    return route
  }

  const routerReplace = router.replace.bind(router)
  router.replace = async (location: RawLocation) => {
    const module = getModule(RouteModule, store)

    const url = new URL(window.location.href)
    url.searchParams.forEach((_, k) => {
      if (EXCLUDE_QUERY_FROM_HISTORY.some(o => o instanceof RegExp ? o.test(k) : o === k)) {
        url.searchParams.delete(k)
      }
    })
    window.history.replaceState({}, '', url.href)

    const route = await routerReplace(location)
    const prunedRoute: IRoute = structuredClone(pick(route, ['fullPath', 'name', 'query', 'params', 'hash', 'meta', 'path']))

    Object
      .keys(prunedRoute.query)
      .filter(k => EXCLUDE_QUERY_FROM_HISTORY.some(o => o instanceof RegExp ? o.test(k) : o === k))
      .forEach(k => {
        delete prunedRoute.query[k]
      })

    module.replaceRoute(prunedRoute)
    return route
  }
}
