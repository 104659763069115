import { RouteConfig } from 'vue-router'

export default [
  {
    component: () => import(/* webpackChunkName: "render-diagram" */ './views/diagram.vue'),
    meta: {
      disableScreenWarning: true,
      redirectUnauth: false,
      requiresUnauth: true
    },
    name: 'render-diagram',
    path: '/render/diagram'
  }
] as RouteConfig[]
