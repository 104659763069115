import VueRouter from 'vue-router'
import { Store } from 'vuex'

import * as env from '@/helpers/env'

import routes from './routes'
import { RenderModule } from './store'

export const install = ({ store, router }: { store: Store<any>, router: VueRouter }) => {
  store.registerModule(RenderModule.namespace, RenderModule)

  if (!env.IS_SHARE_LINK) {
    router.addRoutes(routes)
  }
}
